<template>
  <div>
    <a-modal
        :title="modeltitle"
        :visible="visible"

        @ok="uppassOk()"
        @cancel="visible=false"
        style="width: 200px"

    >
      <a-form-model layout="inline">
        <a-form-model-item label="会员账号"  >
          <a-input style="width: 200px"  v-model="admin.UserId" placeholder="会员账号">

          </a-input >
        </a-form-model-item>
        <a-form-model-item label="卡片名称"  >
          <a-input style="width: 200px"  v-model="admin.Name" placeholder="卡片名称">

          </a-input >
        </a-form-model-item>
        <a-form-model-item label="天数"  >
          <a-input style="width: 200px"  v-model="admin.ValidDays" placeholder="天数">

          </a-input >
        </a-form-model-item>

        <a-form-model-item label="发卡数量"  >
          <a-input style="width: 200px"  v-model="admin.TotalAmount" placeholder="发卡数量">

          </a-input >
        </a-form-model-item>
        <a-form-model-item label="卡封面"  >
          <a-upload
              style=""
              :file-list="fileList"
              name="file"
              action
              :customRequest="uploadImage"

          >
            <a-button>
              <upload-outlined></upload-outlined>
              点击上传
            </a-button>
          </a-upload>
          <a-input disabled="disabled" v-model="admin.Picture" placeholder="资源地址">

          </a-input >
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-layout>
      <a-layout style="background: #fff;padding: 0;margin:0;padding: 10px;">
        <a-form-model layout="inline" :model="queryParam" >

          <a-row style="margin:16px;">
            <a-col :span="2">
              <a-button @click="showModel">发卡</a-button>
            </a-col>
            <a-col :span="5">

              <a-form-model-item label="名称" >
                <a-input v-model="queryParam.Name" placeholder="名称">

                </a-input>
              </a-form-model-item>

            </a-col>



          </a-row>

        </a-form-model>
      </a-layout>
      <a-layout style="background: #fff;padding: 0;margin:16px 0;padding: 16px;">

        <a-table size="small" :rowKey="record => record.Id" :pagination="pagination"   @change="handleTableChange" :columns="columns" :data-source="data" bordered>
          <img  style="width:80px;heigth:80px" slot="pic" slot-scope="text, record" :src=record.Picture />
          <template slot="action" slot-scope="text, record">
            <a-space>
                                <span>
                          <a @click="editclomun(record.Id)">编辑</a>
                        </span>
              <span>
                          <a @click="deleteclomun(record.Id)">删除</a>
                        </span>
            </a-space>
          </template>

        </a-table>
      </a-layout>
    </a-layout>
  </div>

</template>

<script>
export default {
  name: "index",
  data(){
    return {
      modeltitle:'',
      visible:false,
      cardtypelist:[],
      fileList:[
          ],
      pagination: {
        total: 0,
        pageSize: 18,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["18", "20", "50", "100"],//每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
      },
      topsize:'large',
      queryParam:{
        page:1,
        size:18,
        Name:'',


      },

      admin:{},
      roleOption:[

      ],
      columns:[

        {
          title: '名称',
          dataIndex: 'Name'

        },
        {
          title: '有效天数',
          dataIndex: 'ValidDays',
        },

        {
          title: '会员',

          dataIndex: 'Relation'
        },
        {
          title: '总次数',
          dataIndex: 'TotalAmount'

        },
        {
          title: '剩余次数',
          dataIndex: 'Amount',
        },
        {
          title: '图片',
          dataIndex: 'Picture',
          key: 'pic',scopedSlots: { customRender: 'pic' }

        },


        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots:{ customRender: 'action' }

        },
      ],
      data:[]
    }
  },
  mounted(){

    this.GetPageList();
    this.InitCompanys();
  },
  methods:{
    InitCompanys(){
      let that=this;
      that.cardtypelist=[];
      that.$axios.post("/api/Card/GetCardTypeList", null).then((res) => {
        if (res.data.message=="success"){
          that.cardtypelist = res.data.data;
        }
      });
    },
    uploadImage(file){
      let _this = this,
          data = file.file,
          fileParams = new FormData();
      fileParams.append('file', data);
      this.$axios.post("/api/SourceDown/Upload", fileParams,{
        headers: { "Content-Type": "multipart/form-data" }}).then((res) => {
        if (res.data.message == 'success') {
          _this.$set( _this.admin,'Picture',res.data.data[0]);
        }else {
          return _this.$message.error(res.data.message);
        }
      });
    },
    showModel(){
      this.visible=true;this.modeltitle='发卡';this.admin={}
    },
    handleSubmit(){
      this.GetPageList();
    },


    handleTableChange(pagination){
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.queryParam.size = pagination.pageSize;
      this.GetPageList();
    },
    GetPageList(){
      let that=this;
      that.data=[];
      that.pagination.total=0;


      this.$axios.post("/api/Card/GetCardList", that.queryParam).then((res) => {
        if (res.data.message=="success"){
          that.pagination.total = res.data.data.Total;
          that.data = res.data.data.Data;

        }

      });

    },

    deleteclomun(id){
      let that=this;

      that.$axios.post("/api/Card/DeleteCardType", {Id:id}).then((res) => {

        if (res.data.message=='success'){
          that.data=that.data.filter((item)=>{return item.Id!=id});
          that.$message.success('删除成功!');

        }else{
          that.$message.error('删除失败!');
        }
      });


    },
    editclomun(id){
      let that=this;


      that.admin={};
      that.modeltitle='修改';
      that.visible=true;
      that.$axios.post("/api/SourceDown/GetCardType", {Id:id}).then((res) => {
        if (res.data.message=='success'){
          that.admin=res.data.data;
        }

      });

    },
    uppassOk(){
      let that=this;
      let url;
      if (that.modeltitle=='发卡'){
        url="/api/Card/AddCard";
      }
      that.$axios.post(url, that.admin).then((res) => {
        console.log(res)
        if (res.data.message=='success'){
          this.visible=false;
          that.$message.success('操作成功!');
          that.GetPageList();

        }else{
          that.$message.error('操作失败!');
        }
      });
    }
  }
}
</script>

<style scoped>

</style>